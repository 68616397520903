import logo from "./logo.png" ; 


const Logo = () => {
    return (
        <a href="" className="flex items-center">
        <img src={logo} alt = "total Ticket logo" width={150} height={150} /> 
        </a>
    )
}


export default Logo ; 