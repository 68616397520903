import { Link } from "react-router-dom";
import Cards from "../HomeParts/Cards";
import HomeCard from "../HomeParts/HomeCard";
import Welcome from "../HomeParts/Welecom";

const Home = ()  => {
    return (
        <>
       <Welcome />
       <div className="flex justify-center m-5">
        <Link to="/booking" className="bg-blue-500  p-10 text-white px-20 py-2 rounded hover:bg-blue-600">
          Book Now
        </Link>
      </div>
      <Cards />
     
        </>
    )
}

export default Home ; 