

const Footer = () =>{


  return (
<footer class="bg-sky-900 py-6 text-white  bottom-0">
  <div class="container mx-auto text-center">
    <p>&copy; 2023 TotalTicket. All rights reserved for SPU.</p>
  </div>
</footer>
  ); 
}

export default Footer ; 