

const Welcome = () => {
  return ( 
      <section className="bg-sky-900   py-16">
      <div className="container mx-auto">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-white mb-4">Welcome to TotalTicket SPU</h1>
          <p className="text-xl text-white">The easiest way to book tickets for your favorite events</p>
        </div>
      </div>
    </section>
  ); 
}

export default Welcome ; 
